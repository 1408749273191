import { useEffect, useState } from "react";

import { cn } from "~/lib/ui";

import type { Button } from "./button";
import { LoadingButton } from "./loading-button";

export const TriButton = ({
  children,
  isLoading = false,
  doneContent = "✓ Done",
  doneContentTimeout = 2000,
  loadingContent = "Loading...",
  className,
  ...props
}: React.ComponentProps<typeof Button> & {
  isLoading?: boolean;
  doneContent?: React.ReactNode;
  doneContentTimeout?: number;
  loadingContent?: React.ReactNode;
}) => {
  const [state, setState] = useState<"loading" | "done" | "idle">("idle");
  const content =
    state === "idle"
      ? children
      : state === "loading"
        ? loadingContent
        : doneContent;
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    switch (state) {
      case "idle":
        if (isLoading) setState("loading");
        break;
      case "loading":
        if (!isLoading) setState("done");
        break;
      case "done":
        if (!isLoading)
          timer = setTimeout(() => setState("idle"), doneContentTimeout);
        else setState("loading");

        break;
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, state]);

  const isDone = state === "done";

  return (
    <LoadingButton
      {...props}
      className={cn(className, "relative overflow-hidden")}
      isLoading={isLoading || isDone} // Leave the loading variant for a bit more while done state lasts
      loadingIndicatorHidden={isDone}
    >
      <span className="animate-reveal" key={isLoading.toString()}>
        {content}
      </span>
    </LoadingButton>
  );
};
