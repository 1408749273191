import type { ButtonProps } from "./button";
import { Button } from "./button";

export type Props<T extends React.ElementType = "button"> = {
  isLoading?: boolean;
  loadingIndicatorHidden?: boolean;
} & ButtonProps<T> & {
    ref?: React.ForwardedRef<React.ElementRef<T>>;
  } & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>;

export function LoadingButton<T extends React.ElementType = "button">({
  isLoading = false,
  loadingIndicatorHidden = false,
  children,
  ...rest
}: Props<T>) {
  return (
    <Button
      {
        ...(rest as any) /*I give up on this type*/
      }
      variant={isLoading ? "loading" : rest.variant}
    >
      {isLoading && !loadingIndicatorHidden && <LoadingIndicator />}
      {children}
    </Button>
  );
}

function LoadingIndicator() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className="mr-3 mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 6.1875C12.4393 6.1875 12.1875 6.43934 12.1875 6.75V8.25C12.1875 8.56066 12.4393 8.8125 12.75 8.8125H14.25C14.5607 8.8125 14.8125 8.56066 14.8125 8.25V6.75C14.8125 6.43934 14.5607 6.1875 14.25 6.1875H12.75ZM13.3125 7.6875V7.3125H13.6875V7.6875H13.3125Z"
        fill="#FCFCFD"
        className="animate-bounce [animation-delay:-0.3s]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6875 8.25C7.6875 7.93934 7.93934 7.6875 8.25 7.6875H9.75C10.0607 7.6875 10.3125 7.93934 10.3125 8.25V9.75C10.3125 10.0607 10.0607 10.3125 9.75 10.3125H8.25C7.93934 10.3125 7.6875 10.0607 7.6875 9.75V8.25ZM8.8125 8.8125V9.1875H9.1875V8.8125H8.8125Z"
        fill="#FCFCFD"
        className="animate-bounce [animation-delay:-0.15s]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1875 9.75C3.1875 9.43934 3.43934 9.1875 3.75 9.1875H5.25C5.56066 9.1875 5.8125 9.43934 5.8125 9.75V11.25C5.8125 11.5607 5.56066 11.8125 5.25 11.8125H3.75C3.43934 11.8125 3.1875 11.5607 3.1875 11.25V9.75ZM4.3125 10.3125V10.6875H4.6875V10.3125H4.3125Z"
        fill="#FCFCFD"
        className="animate-bounce"
      />
    </svg>
  );
}
